import axios from 'axios'
// 创建axios实例
const service = axios.create({
    baseURL: document.location.protocol + process.env.VUE_APP_BASE_API, // api的base_url
    timeout: 600000,                 // 请求超时时间
    withCredentials: true,           //携带cookie
})
export default {
    postAction: function (url, parameter) {
        return service({
            url: url,
            method: 'post',
            data: parameter,
            headers: {
                Token: localStorage.getItem("tk"),
                ptoken: localStorage.getItem("ptoken"),
            }
        }).then(res => {
            if (res.data.sub_code && res.data.sub_code == "common.sys-to-login") {
                var t = new Date().getTime();
                window.$vue.$router.push({
                    name: "Login", query: {
                        t: t,
                    }
                });
            }
            return res.data;
        })
    },
    httpAction: function (url, parameter, method) {
        return service({
            url: url,
            method: method,
            data: parameter,
            headers: {
                Token: localStorage.getItem("tk"),
                ptoken: localStorage.getItem("ptoken"),
            }
        }).then(res => {
            if (res.data.sub_code && res.data.sub_code == "common.sys-to-login") {
                var t = new Date().getTime();
                window.$vue.$router.push({
                    name: "Login", query: {
                        t: t,
                    }
                });
            }
            return res.data;
        });
    },
    putAction: function (url, parameter) {
        return service({
            url: url,
            method: 'put',
            data: parameter,
            headers: {
                Token: localStorage.getItem("tk"),
                ptoken: localStorage.getItem("ptoken"),
            }
        }).then(res => {
            if (res.data.sub_code && res.data.sub_code == "common.sys-to-login") {
                var t = new Date().getTime();
                window.$vue.$router.push({
                    name: "Login", query: {
                        t: t,
                    }
                });
            }
            return res.data;
        })
    },
    getAction: function (url, parameter) {
        return service({
            url: url,
            method: 'get',
            params: parameter,
            headers: {
                Token: localStorage.getItem("tk"),
                ptoken: localStorage.getItem("ptoken"),
            }
        }).then(res => {
            if (res.data.sub_code && res.data.sub_code == "common.sys-to-login") {
                var t = new Date().getTime();
                window.$vue.$router.push({
                    name: "Login", query: {
                        t: t,
                    }
                });
            }
            return res.data;
        })
    },
}
